const setMobileActiveStateListener = () => {
    // Get the hamburger button element
    const hamburger = document.getElementById('hamburger') as HTMLButtonElement

    const mm_toggle_elements = [
        document.body,
        document.querySelector('aside.mobile_controls') as HTMLElement,
        document.querySelector('nav.mobile') as HTMLElement,
    ]

    // Add click event listener to the hamburger button
    hamburger.addEventListener('click', () => {
        // Toggle the 'is-active' class for each element
        hamburger.classList.toggle('is-active')
        mm_toggle_elements.forEach(
            el =>
                (el.dataset.mmActive =
                    el.dataset.mmActive === 'true' ? 'false' : 'true')
            // el.setAttribute(
            //     'data-mm-active',
            //     el.getAttribute('data-mm-active') === 'true' ? 'false' : 'true'
            // )
        )
    })
}

const setCopyrights = () => {
    // Get all elements with 'data-copyright' attribute
    const copyrights: NodeListOf<HTMLElement> =
        document.querySelectorAll('[data-copyright]')

    // Set the inner text of each copyright element to the COPYRIGHT value from the environment
    copyrights.forEach((el: HTMLElement) => {
        el.innerText = env.COPYRIGHT
    })
}

const isLongTestimonial = () => {
    const max_length = 180

    const testimonial = document.querySelector(
        'body > div.wrapper > footer div.testimonial'
    )
    const size = testimonial?.getAttribute('data-count')

    if (size && parseInt(size) > max_length) testimonial?.classList.add('long')
}

const setImageFocalPoints = () => {
    // Function to get an attribute value as a number
    const getAttributeAsNumber = (el: HTMLElement, attr: string) => {
        const attrValue = el.getAttribute(attr)
        return attrValue ? parseInt(attrValue) : null
    }

    // For each image element
    ;(
        document.querySelectorAll('img, source') as NodeListOf<HTMLElement>
    ).forEach(el => {
        // Get the values of the specified attributes as numbers
        const [w, h, focal_x, focal_y] = [
            'data-width',
            'data-height',
            'data-focal-point-x',
            'data-focal-point-y',
        ].map(attr => getAttributeAsNumber(el, attr))

        // If any of the values are null, return
        if (!w || !h || !focal_x || !focal_y) return

        // Set the object position of the image based on the focal point
        el.style.objectPosition = `${(focal_x / w) * 100}% ${
            (focal_y / h) * 100
        }%`
    })
}

;(() => {
    // Set the mobile active state listener
    setMobileActiveStateListener()

    // Set the COPYRIGHT value from the environment to each element with 'data-copyright' attribute
    setCopyrights()

    // Adjust Footer Testimonial CSS
    isLongTestimonial()

    // Set the focal point of each image
    // setImageFocalPoints()
})()
